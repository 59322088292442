<template>
  <div class="chat">
    <v-app-bar>
      <button
        type="button"
        @click="hasHistory() ? $router.go(-1) : $router.push('/')"
        class="my-5 btn btn-outline-success"
      >
        <v-icon>mdi-chevron-left</v-icon> Back
      </button>
      <v-toolbar-title class="text-right"
        ><a href="#"> Ringkasan konsultasi</a></v-toolbar-title
      >
      <!-- <h3 class="px-3 text-center">Chat admin</h3> -->
    </v-app-bar>
    <v-container>
      <!-- <h1>This is an chat page</h1> -->
      <div
        class="text-center text--secondary mx-auto"
      >
        <v-icon
          style="font-size: 60px; margin-top: 2em"
          color="warning darken-1"
        >
          mdi-timer-sand
        </v-icon>
        <p class="headline mt-2 mb-1">
          Tunggu sebentar chat segera terbuka ...
        </p>
      </div>
    </v-container>
    <BottomNav />
  </div>
</template>
<script>
import { GetProfile } from "../services/api";
import helpers from "../helpers/auth";
import BottomNav from "../components/BottomNav.vue";
export default {
  name: "chat",
  components: { BottomNav },
  data: () => ({
    isLoading: true,
    profile: [],
  }),
  mounted() {
    this.loadChat();
    this.isLoading = false;
    GetProfile(this.userData.token).then((res) => {
      this.profile = res.data.data;
      console.log(this.profile);
      localStorage.setItem("username", this.profile.nama);
      localStorage.setItem("email", this.profile.email);
    });
  },
  computed: {
    userData() {
      return helpers.getData();
    },
  },
  methods: {
    hasHistory() {
      return window.history.length > 2;
    },
    loadChat() {
      window.__lc = window.__lc || {};
      window.__lc.license = 12331875;
      (function (n, t, c) {
        function i(n) {
          return e._h ? e._h.apply(null, n) : e._q.push(n);
        }
        var e = {
          _q: [],
          _h: null,
          _v: "2.0",
          on: function () {
            i(["on", c.call(arguments)]);
          },
          once: function () {
            i(["once", c.call(arguments)]);
          },
          off: function () {
            i(["off", c.call(arguments)]);
          },
          get: function () {
            if (!e._h)
              throw new Error(
                "[LiveChatWidget] You can't use getters before load."
              );
            return i(["get", c.call(arguments)]);
          },
          call: function () {
            i(["call", c.call(arguments)]);
          },
          init: function () {
            var n = t.createElement("script");
            (n.async = !0),
              (n.type = "text/javascript"),
              (n.src = "https://cdn.livechatinc.com/tracking.js"),
              t.head.appendChild(n);
          },
        };
        !n.__lc.asyncInit && e.init(),
          (n.LiveChatWidget = n.LiveChatWidget || e);
        n.LiveChatWidget.call("maximize");
        var username = localStorage.getItem("username");
        var email = localStorage.getItem("email");
        n.LiveChatWidget.call("set_customer_name", username);
        n.LiveChatWidget.call("set_customer_email", email);
      })(window, document, [].slice);
    },
  },
};
</script>
<!-- End of LiveChat code -->
<!-- End of LiveChat code -->

<style>
div#chat-widget-container {
  right: 0 !important;
}
.v-toolbar__title > a {
  color: #a97e3d;
  text-decoration: none;
}
</style>
